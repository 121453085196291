.container {
  position: absolute;
  left: 0;
  top: 8px;
  height: 47px;
  max-height: 45px;
  min-height: 45px;
  padding: 0 10px 0 0;
  margin-left: 55px;
  width: calc(100% - 55px);
  user-select: none;
  pointer-events: none;
}

.menuBar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.ponponButton {
  background-color: rgb(10 10 10) !important;
  font-size: 20px !important;
  margin-left: 5px !important;
  margin-top: 2px !important;
  padding: 10px 0 !important;
  pointer-events: all;
}

.menuBarButton {
  margin-left: 5px;
  font-weight: 600;
  pointer-events: all;
}

.leftButtons {
  width: calc(100%);
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row;
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.centerButtons {
  background-color: rgb(10 10 10);
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: row;
  position: relative;
  z-index: 1;
  justify-content: center;
}

.rightButtons {
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  flex-direction: row;
  background-color: rgb(10 10 10);
  padding-left: 20px;
  pointer-events: all;
}
