/* stylelint-disable selector-class-pattern */
:global(#downloads-control-id) {
  display: none;
}

.searchInput {
  border-radius: 20px !important;
  height: 35px !important;
  border: none !important;

  &.rs-input-group.rs-input-group-inside {
    background-color: #252525 !important;

    &:focus-visible,
    &:focus,
    &:focus-within {
      background-color: rgb(255 255 255 / 100%) !important;
      color: #4a4a4a !important;

      .rs-input-group-addon svg {
        color: #4a4a4a !important;
      }
    }
  }

  .searchInputInput {
    background-color: transparent !important;
    width: 250px !important;
    transition: width 0.33s;

    &:focus {
      width: 320px !important;
    }
  }

  .menu {
    cursor: pointer;
  }
}
